<template>
  <app-loading-container
    class="w-full px-4 py-12 mb-32 lg:py-16 sm:px-10"
    :loading="loading"
    :loaded="loaded"
    :error-loading="errorLoading"
  >
    <div>
      <csv-download-link
        v-if="loaded && metrics"
        :data="trips"
        :fields="[
          { label: 'First Name', value: 'fname' },
          { label: 'Last Name', value: 'lname' },
          { label: 'NFC Card ID', value: 'nfc_id' },
          { label: 'Trips per month', value: 'trip_count' },
          { label: 'Joined', value: 'created_at' },
        ]"
        :download-name="`${metrics.staffInfo.fname}-${metrics.staffInfo.lname}-staff-metrics.csv`"
        class="absolute bottom-0 right-0 mb-6 mr-6"
      >
        <svg width="56" height="56" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <circle fill="#20E682" cx="28" cy="28" r="28" />
            <path d="M14 14h28v28H14z" />
            <path
              stroke="#FFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M23.406 26.031L28 30.625l4.594-4.594M28 18.372v12.25"
            />
            <path
              d="M38.5 28.875v7.875a.875.875 0 01-.875.875h-19.25a.875.875 0 01-.875-.875v-7.875"
              stroke="#FFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </csv-download-link>
      <div class="px-6">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="w-1/4 h-4 bg-gray-400 rounded"></div>
          </div>
        </template>
        <template v-else>
          <router-link to="/users" class="text-xs font-medium text-green-type-1">Users /</router-link>
          <span class="text-xs font-medium text-gray-type-3" v-if="metrics"
            >&nbsp;{{
              `${metrics.staffInfo.fname} ${metrics.staffInfo.lname}`
            }}</span
          >
        </template>
      </div>
      <div class="grid w-full gap-8 mt-8 sm:grid-cols-3">
        <div class="px-8 py-6 bg-white rounded-xl">
          <template v-if="loading">
            <div class="animate-pulse">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="mt-6 space-y-4">
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <div class="font-bold uppercase text-xxs text-gray-type-8">
                Total trips
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mt-6 text-2xl font-bold text-gray-type-2">
                {{ metrics.totalTrips }}
              </div>
              <div class="ml-auto text-right"></div>
            </div>
          </template>
        </div>
        <div class="px-8 py-6 bg-white rounded-xl">
          <template v-if="loading">
            <div class="animate-pulse">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="mt-6 space-y-4">
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <div class="font-bold uppercase text-xxs text-gray-type-8">
                Total routes
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mt-6 text-2xl font-bold text-gray-type-2">
                {{ metrics.totalRoutes }}
              </div>
              <div class="ml-auto text-right"></div>
            </div>
          </template>
        </div>
        <div class="px-8 py-6 bg-white rounded-xl">
          <template v-if="loading">
            <div class="animate-pulse">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="mt-6 space-y-4">
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
                <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex items-center justify-between">
              <div class="font-bold uppercase text-xxs text-gray-type-8">
                Total fare amounts
              </div>
            </div>
            <div class="flex justify-between">
              <div class="mt-6 text-2xl font-bold text-gray-type-2">
                ₦ {{ $filters.money(metrics.totalFare) }}
              </div>
              <div class="ml-auto text-right"></div>
            </div>
          </template>
        </div>
      </div>
      <div class="mt-10">
        <div
          class="items-center mt-6 space-y-4 lg:space-y-0 lg:space-x-4 lg:flex"
        >
          <div class="w-full lg:w-3/5">
            <div class="relative">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="w-4 h-4 pointer-events-none text-gray-type-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                v-model="search"
                type="search"
                class="w-full h-12 px-3 py-4 pl-10 text-xs font-medium bg-white outline-none plachol placeholder-gray-type-3 focus:outline-none"
                placeholder="Search trip"
              />
            </div>
          </div>
          <div
            class="items-center flex-1 space-y-4 lg:flex lg:space-x-4 lg:space-y-0"
          >
            <div class="flex-shrink-0 text-xs font-medium text-gray-type-2">
              See trips in
            </div>
            <select
              class="w-full h-12 px-3 py-4 text-xs font-medium bg-white outline-none placeholder-gray-type-3 focus:outline-none"
              v-model="params.month"
            >
              <option
                v-for="(month, index) in monthOptions"
                :key="index"
                :value="month.value"
              >
                {{ month.label }}
              </option>
            </select>
            <select
              class="w-full h-12 px-3 py-4 text-xs font-medium bg-white outline-none placeholder-gray-type-3 focus:outline-none"
              v-model="params.year"
            >
              <option
                v-for="(year, index) in yearOptions"
                :key="index"
                :value="year.value"
              >
                {{ year.label }}
              </option>
            </select>
          </div>
        </div>
        <app-table
          :loading="fetchingTrips"
          :error-loading="errorFetchingTrips"
          :items="filteredTrips"
          :fields="tripsFields"
          class="mt-2"
        >
          <template v-slot:route_name="{ item }">
            <div class="text-xs font-medium text-gray-type-3">
              {{ item.pickup }} - {{ item.destination }}
            </div>
          </template>
          <template v-slot:date="{ item }">
            <span class="text-xs font-medium text-gray-type-3">
              {{ $filters.dateTime(item.created_at) }}
            </span>
          </template>
        </app-table>
      </div>
    </div>
  </app-loading-container>
</template>

<script>
import { mapGetters } from "vuex";
import AppTable from "@/components/AppTable";
import CsvDownloadLink from "@/components/CsvDownloadLink";

export default {
  props: ["id"],
  components: {
    AppTable,
    CsvDownloadLink
  },
  data() {
    return {
      loading: false,
      loaded: false,
      errorLoading: false,
      metrics: null,
      fetchingTrips: false,
      errorFetchingTrips: false,
      search: "",
      params: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      },
      monthOptions: [
        {
          label: "Jan",
          value: 1,
        },
        {
          label: "Feb",
          value: 2,
        },
        {
          label: "Mar",
          value: 3,
        },
        {
          label: "Apr",
          value: 4,
        },
        {
          label: "May",
          value: 5,
        },
        {
          label: "Jun",
          value: 6,
        },
        {
          label: "Jul",
          value: 7,
        },
        {
          label: "Aug",
          value: 8,
        },
        {
          label: "Sept",
          value: 9,
        },
        {
          label: "Oct",
          value: 10,
        },
        {
          label: "Nov",
          value: 11,
        },
        {
          label: "Dec",
          value: 12,
        },
      ],
      startYear: 2019,
      trips: [],
      tripsFields: [
        { key: "route_name", label: "Trip" },
        { key: "date", label: "Date" },
      ],
    };
  },
  created() {
    this.init();
  },
  watch: {
    "params.month"() {
      this.fetchTrips();
    },
    "params.year"() {
      this.fetchTrips();
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    filteredTrips() {
      return this.trips.filter(
        (trip) =>
          trip.pickup.toLowerCase().includes(this.search.toLowerCase()) ||
          trip.destination.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    yearOptions() {
      const options = [];

      for (let i = this.startYear; i <= new Date().getFullYear(); i++) {
        options.push({
          label: i,
          value: i,
        });
      }

      return options;
    },
  },
  methods: {
    init() {
      this.loading = true;

      this.errorLoading = this.loaded = false;

      Promise.all([this.fetchMetrics(), this.fetchTrips()])
        .then(() => {
          this.loaded = true;
        })
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => (this.loading = false));
    },
    fetchMetrics() {
      this.$http
        .get(`/corporates/${this.user.id}/staff/${this.id}/metrics`)
        .then((res) => {
          this.metrics = res.data;
        })
        .catch((err) => {
          if (err.response && err.response.status === 500) {
            return this.$router.push({
              name: "dashboard.home",
            });
          }
        });
    },
    fetchTrips() {
      this.fetchingTrips = true;

      this.errorFetchingTrips = false;

      this.$http
        .get(
          `/corporates/${this.user.id}/staff/${
            this.id
          }/trips?${new URLSearchParams(this.params).toString()}`
        )
        .then((res) => {
          this.trips = res.data;
        })
        .catch(() => {
          this.errorFetchingTrips = true;
        })
        .finally(() => {
          this.fetchingTrips = false;
        });
    },
  },
};
</script>